<template>
  <main class="flex bg-transparent xl:px-6 lg:px-6 md:px-8 px-3">
    <section class="w-full">
      <main>
        <h1 class="text-xl font-bold capitalize">My Transactions</h1>
        <section class="flex items-center">
          <div class="w-full flex xl:flex-row lg:flex-row md:flex-col flex-col">
            <dashboard-card
              class="bg-primary-color mr-6"
              @toggleBalance="toggleWalletBalance"
              :show-balance="showWalletBalance"
              :data-set="'balance'"
            >
              <img
                src="@/assets/images/subscription-icon-light.svg"
                alt="go to subscriptions"
                slot="icon"
                class="w-8"
              />
              <span slot="title">Wallet Balance</span>
              <span slot="action" class="text-primary-color">Top up</span>
              <span slot="amount" class="inline-block w-14">
                <span v-if="showWalletBalance" class="w-full"
                  >₦{{ walletBalance }}</span
                >
                <img
                  v-else
                  src="@/assets/images/encrypt.svg"
                  alt="wallet balance"
                  class="w-full"
                />
              </span>
            </dashboard-card>
            <dashboard-card
              class="bg-primary-green dark:bg-dark-mode-green"
              @toggleBalance="toggleRewardPoints"
              :show-balance="showRewardPoints"
              :data-set="'rewards'"
            >
              <img
                src="@/assets/images/reward-pack.svg"
                alt="go to subscriptions"
                slot="icon"
              />
              <span slot="title">Total Reward points</span>
              <span
                slot="action"
                class="text-primary-green dark:text-dark-mode-green"
                >Redeem</span
              >
              <span slot="amount" class="inline-block w-14">
                <span v-if="showRewardPoints" class="full">{{
                  rewardPoints
                }}</span>
                <img
                  v-if="!showRewardPoints"
                  src="@/assets/images/encrypt.svg"
                  alt="reward points"
                  class="w-full"
                />
              </span>
            </dashboard-card>
          </div>
        </section>
        <div
          class="
            switch-container
            rounded-lg
            flex
            items-center
            p-1
            my-12
            font-semibold
            text-sm text-legend-gray text-center
            dark:text-border-color
          "
        >
          <div
            class="
              w-1/3
              cursor-pointer
              py-3
              xl:px-6
              lg:px-6
              md:px-3
              px-3
              rounded-lg
            "
            :class="{ 'bg-white text-primary-color': isWalletTransactions }"
            @click="toggleWalletTransactions"
          >
            Wallet Transactions
          </div>
          <div
            class="
              w-1/3
              cursor-pointer
              py-3
              xl:px-6
              lg:px-6
              md:px-3
              px-3
              rounded-lg
            "
            :class="{ 'bg-white text-primary-color': isInternetUsage }"
            @click="toggleInternetUsage"
          >
            Internet Usage
          </div>
          <div
            class="
              w-1/3
              cursor-pointer
              py-3
              xl:px-6
              lg:px-6
              md:px-3
              px-3
              rounded-lg
            "
            :class="{ 'bg-white text-primary-color': isCheckoutRecords }"
            @click="toggleCheckoutRecords"
          >
            Checkout Records
          </div>
        </div>
        <LoadingSpinner v-if="loading && !transactions" />
        <div
          v-if="!loading && transactions && transactions.length"
          class="my-12"
        >
          <DataTable :transactions="transactions" />
          <PaginationComponent
            v-if="result"
            :result="result"
            @fetchByPage="fetchTransactions"
          />
        </div>
        <div
          v-if="!loading && transactions && !transactions.length"
          class="text-center font-semibold py-16"
        >
          No data found
        </div>
      </main>
    </section>
  </main>
</template>

<script>
import DashboardCard from '@/components/DashboardCard.vue'
import DataTable from '@/components/DataTable.vue'
import PaginationComponent from '@/components/Pagination.vue'
import { mapGetters } from 'vuex'
import errorModule from '@/util/error.handle'

export default {
  name: 'Transactions',
  components: {
    DashboardCard,
    DataTable,
    PaginationComponent,
  },
  mounted() {
    this.switchTheme()
    this.fetchTransactions(1)
  },
  watch: {
    mode() {
      this.switchTheme()
    },
  },
  data() {
    return {
      transactions: null,
      result: null,
      isWalletTransactions: true,
      isInternetUsage: false,
      isCheckoutRecords: false,
      showWalletBalance: false,
      showRewardPoints: false,
      notification: null,
      loading: false,
    }
  },
  computed: {
    ...mapGetters(['walletBalance', 'rewardPoints', 'mode']),
  },
  methods: {
    switchTheme() {
      const node = document.querySelector('.switch-container')
      if (this.mode === 'dark') {
        node.classList.add('dark-mode-bg')
        node.classList.remove('light-mode-bg')
      } else {
        node.classList.add('light-mode-bg')
        node.classList.remove('dark-mode-bg')
      }
    },
    toggleWalletBalance(value) {
      value ? (this.showWalletBalance = true) : (this.showWalletBalance = false)
    },
    toggleRewardPoints(value) {
      value ? (this.showRewardPoints = true) : (this.showRewardPoints = false)
    },
    toggleWalletTransactions() {
      this.isWalletTransactions = true
      this.isInternetUsage = false
      this.isCheckoutRecords = false
      this.fetchTransactions(1)
    },
    toggleInternetUsage() {
      this.isWalletTransactions = false
      this.isInternetUsage = true
      this.isCheckoutRecords = false
      this.fetchRecentTransactions('internet_usage')
    },
    toggleCheckoutRecords() {
      this.isWalletTransactions = false
      this.isInternetUsage = false
      this.isCheckoutRecords = true
      this.fetchRecentTransactions('checkout_history')
    },
    async fetchTransactions(pageNo) {
      try {
        this.transactions = null
        this.loading = true
        if (pageNo === '...' || pageNo < 1) {
          return
        }

        if (this.result && pageNo > this.result.last_page) {
          return
        }

        const { status, data } = await this.axios.get(
          `/account/transactions?page=${pageNo}`
        )

        if (status === 200) {
          const _result = []

          data.data.data.forEach((transaction) => {
            if (transaction.type === 'Bank Transfer') {
              transaction.bank_transfer_receipt = {
                amount_transfered: transaction.amount_transfered,
                recipient_account_name: transaction.recipient_account_name,
                recipient_account_number: transaction.recipient_account_number,
                recipient_bank_name: transaction.recipient_bank_name,
                sender_name: transaction.sender_name,
                transaction_status: transaction.transaction_status,
              }

              _result.push(transaction)
            } else {
              _result.push(transaction)
            }
          })

          this.transactions = _result

          this.result = data.data
          this.loading = false
        }
      } catch (error) {
        this.loading = false
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
      }
    },
    async fetchRecentTransactions(filter) {
      try {
        this.transactions = null
        this.loading = true
        this.result = null
        const { status, data } = await this.axios.get(
          `/account/transactions?filter=${filter}`
        )

        if (status === 200) {
          this.transactions = data.data.data
          this.loading = false
        }
      } catch (error) {
        this.loading = false
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
      }
    },
  },
}
</script>

<style scoped>
.switch-container {
  width: 35rem;
}

.light-mode-bg {
  background-color: rgb(253, 79, 0, 0.1);
}

.dark-mode-bg {
  background-color: #1c1c1c;
}

@media only screen and (max-width: 560px) {
  .switch-container {
    width: 100%;
  }

  .switch-container > div {
    font-size: 0.7rem;
  }
}
</style>
